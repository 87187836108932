import Vue from "vue";
import Router from "vue-router";
import NotFound from "./views/404.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "404",
      component: NotFound
    },

    {
      path: "/C.5.*",
      name: "ScanAndGo Gift Box",
      component: () => import("./views/ScanAndGo_giftbox.vue")
    },

    {
      path: "/C.*",
      name: "ScanAndGo",
      component: () => import("./views/ScanAndGo.vue")
    },

    {
      path: "/GW.WIFI:ssid?",
      name: "Wifi",
      component: () => import("./views/Wifi.vue")
    },

    {
      path: "/MC.*",
      name: "MerchantQR",
      component: () => import("./views/MerchantQR.vue")
    }
  ]
});
