<template>
  <div class="404 text-center">
    <h1>Not Found</h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
